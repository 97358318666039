import React, { useEffect, useState } from 'react'
import { getLabOrders, getOrders } from '../../services/AsyncFunctions';
import toast from 'react-hot-toast';
import OrderCard from '../components/cards/OrderCard';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import LabOrderCard from '../components/cards/LabOrderCard';

function LabOrders() {
    const [type, setType] = useState("pending");
    const  [key, setKey] = useState("pending")
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [orders, setOrders] = useState([]);
    console.log(orders, "order list pharma")
    const [isLoading, setIsLoading] = useState(false);
  


    async function fetchOrders(type) {
        // if(!hasMoreData) return;
        setOrders(null);
        setIsLoading(true);
        try {
          let response = await getLabOrders(
            type,
            currentPage
          );
          console.log(response, "lab orders list")
          let updatedList = orders
            ? [...orders, ...response.data.data]
            : [...response.data.data];
          // setOrders([...updatedList]);
          // console.log(first)
          setOrders(response.data.data);

          setHasMoreData(
            response.data.data.length < 10 ? false : true
          );
        } catch (error) {
          toast.error(error?.response?.data?.message);
        } finally {
          setIsLoading(false);
        }
      } 
      
useEffect(()=>{
  fetchOrders('pending')
},[])
  return (
    <div>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
        <h2 className="text-black font-w600 mb-0">Orders </h2>
        </div>
      </div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => {setKey(k); fetchOrders(k)}}
        className="mb-3"
      >
        <Tab eventKey="pending" title="Pending Orders">
          {orders &&
            orders.map((item) => (
              <LabOrderCard
                key={item._id}
                patientName={item?.user?.name}
                doctorName={item?.doctor?.name}
                medicines={item?.test}
                orderId={item?._id}
                // orderMode={item?.method == "online" ? "Delivery" : "Pickup"}
              />
            ))}
          {orders && orders.length == 0 && (
            <div className="text-center">
              <h3>No Data</h3>
            </div>
          )}
          {isLoading && (
            <div className="d-flex align-items-center justify-content-center mb-3">
              <Spinner animation="border" variant="info" />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center">
            {hasMoreData && !isLoading && (
              <button
                className="fs-14 p-2 px-3 btn-warning btn m-auto"
                // onClick={() => loadMoreAppointments("upcoming")}
              >
                Load more
              </button>
            )}
          </div>
        </Tab>
        <Tab eventKey="onGoing" title="In Progress Orders" >
        {orders &&
            orders.map((item) => (
              <LabOrderCard
                key={item._id}
                patientName={item?.user?.name}
                doctorName={item?.doctor?.name}
                medicines={item?.test}
                orderId={item?._id}
                // orderMode={item?.method == "online" ? "Delivery" : "Pickup"}
              />
            ))}
          </Tab>
          {/* <Tab eventKey="cancelled" title="Cancelled Orders">

          {orders &&
            orders.map((item) => (
              <OrderCard
                key={item._id}
                patientName={item?.user?.name}
                doctorName={item?.doctor?.name}
                medicines={item?.prescriptions}
                orderId={item?._id}
                orderMode={item?.method == "online" ? "Delivery" : "Pickup"}
              />
            ))}
          </Tab> */}
          {/* <Tab eventKey="completed" title="Completed Orders"></Tab> */}
        </Tabs>

    </div>
  )
}

export default LabOrders