import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../img/brand-header-logo.svg";
import logoText from "../../../images/TRAIN TAB.svg";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="nav-header" style={{ zIndex: "12" }}>
      <Link to="/" className="brand-logo">
        <img className="logo-compact ml-5" src={logo} alt="" />
        <img className="brand-title ml-5" src={logo} alt="" />
        {/* <img className="logo-abbr" src={logo} alt="" />
     
        {/* <h3 className="brand-title ml-2 login-page-logo">DOCTOR</h3>
        <h3 className="logo-compact ml-2 login-page-logo">DOCTOR</h3> */}
        {/* <h5 className="logo-abbr text-black">H</h5> */}
      </Link>

      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
