import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { postMedicine } from "../../services/AsyncFunctions";

function AddMedicine({ show, onHide, refetch }) {
    const [loading, setLoading] = useState(false);
    const  [payload, setPayload] = useState({
        medicineName: "",
        medicineSalt: "",
        costPerUnit: "",
        stockCount: 0
    })

    async function addNewMedicine(){
        if(payload.medicineName.trim() == "" || payload.costPerUnit.trim() == ""){
            toast.error("Medicine Name and Cost is required");
            return;
        }
        setLoading(true);
        try{
            const response = await postMedicine(payload);

            refetch();

            setPayload({
                medicineName: "",
                medicineSalt: "",
                costPerUnit: "",
                stockCount: 0
            })
            onHide()

        }catch(error){
            toast.error(error?.response?.data?.message)
        }finally{
            setLoading(false)
        }
    }

    function handleFormUpdate(e){
        setPayload({
            ...payload,
            [e.target.name] : e.target.value
        })
    }
  return (
    <Modal className="fade" show={show} centered onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Add Medicine</Modal.Title>
        <Button onClick={onHide} variant="" className="close">
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          className="form-control input-default mb-2"
          placeholder="Medicine name"
          name="medicineName"
          value={payload.medicineName}
          onChange={(e)=>handleFormUpdate(e)}
        />
        <input
          type="text"
          className="form-control input-default mb-2"
          placeholder="Salt name"
          value={payload.medicineSalt}
          name="medicineSalt"
          onChange={(e)=>handleFormUpdate(e)}


        />
        <input
          type="text"
          className="form-control input-default mb-2"
          placeholder="Cost per unit"
          value={payload.costPerUnit}
          name="costPerUnit"
          onChange={(e)=>handleFormUpdate(e)}


        />
        <input
          type="number"
          className="form-control input-default mb-2"
          placeholder="Items in stock"
          value={payload.stockCount}
          name="stockCount"
          onChange={(e)=>handleFormUpdate(e)}


        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="w-100" disabled={loading} onClick={addNewMedicine}>
              {loading ?  <Spinner animation="border"/> : "Add To Inventory"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddMedicine;
