import React, {useEffect, useState, useRef } from 'react';
import {Link} from 'react-router-dom';
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineAdd } from "react-icons/md";
import AddMedicine from '../modal/AddMedicine';
import { getInventory, postMedicine } from '../../services/AsyncFunctions';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';



const tableDataBlog = [
    {title:"Emilia Johnson"},
    {title:"Mark Steven"},
    {title:"Cindy Stark"},
    {title:"David Lee"},
    {title:"James Rodriguez"},
    {title:"Tommy Hank"},
    {title:"Emilia Johnson"},
    {title:"Mark Steven"},
    {title:"Cindy Stark"},
    {title:"David Lee"},
    {title:"James Rodriguez"},
    {title:"Tommy Hank"},
    
];

const InventoryManagement = () => {

    const [showAddMedicineModal, setShowAddMedicineModal] = useState(false);
    const [inventoryData, setInvetoryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchString, setSearchString] = useState("");



    async function fetchInventory(){
        setLoading(true);
        try {
            const response = await getInventory(searchString);
            console.log(response)
            setInvetoryData(response.data.data.medicines);
        } catch (error) {
                toast.error(error?.response?.data?.message)
        }finally{
            setLoading(false)
        }
    }

useEffect(()=>{
    const delayDebounceFn = setTimeout(() => {
        // Call the fetchNotes function after debounce timeout
            fetchInventory();
      }, 500); // Debounce timeout (500 milliseconds)
  
      return () => clearTimeout(delayDebounceFn); 
},[searchString])

    return (
        <>
            <AddMedicine show={showAddMedicineModal} onHide={()=> setShowAddMedicineModal(false)}  refetch={fetchInventory}/>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="form-head dashboard-head d-flex  mb-4 align-items-center justify-content-between">
                    <h2 className="text-black font-w600 mb-0">Inventory </h2>
                    <div className='d-flex align-items-center gap-2'>
                        <div className="input-group search-area">
                            <input type="text" className="form-control" placeholder="Search here..." onChange={(e)=> setSearchString(e.target.value)}/>
                            <span className="input-group-text rounded-0 pointer"><Link to={"#"}><i className="flaticon-381-search-2"></i></Link></span>
                        </div>
                        <button className='btn btn-secondary' onClick={()=>setShowAddMedicineModal(true)}>Add</button>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <div  id="example5_wrapper" className="dataTables_wrapper no-footer">  
                                    <table id="example5" className="display mb-4 w-100 defaultTable dataTablesCard dataTable no-footer" style={{minWidth:"845px"}}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Medicine Name</th>
                                                <th>Salt Name</th>
                                                <th>Price Per Unit($)</th>
                                                <th>Stock Count</th>
                                                <th>Updated On</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading  && inventoryData && inventoryData.map((data, ind)=>(
                                                <tr key={ind}>
                                                   <td>{ind + 1}</td>
                                                    <td><strong>{data.medicineName}</strong></td>
                                                    <td><strong className="text-primary">{data.medicineSalt ? data.medicineSalt : "--" }</strong></td>
                                                    <td>{data.costPerUnit}</td>
                                                    <td>{data.stockCount}</td>
                                                    <td>{moment(data.updatedAt).format("DD-MM-YYYY")}</td>
                                                    <td>
                                                        <div className="action-buttons d-flex align-items-center justify-content-end">                                                            
                                                            <Link to={"#"} className="btn btn-primary mr-2">
                                                     
                                                                <FaRegEdit />
                                                            
                                                            </Link>
                                                            <Link to={"#"} className="btn btn-danger light">
                                                          
                                                                <MdDeleteOutline />
                                                            </Link>
                                                        </div>
                                                    </td>												
                                                </tr>
                                            ))}


                                        </tbody>

                                        {loading && <div className='d-flex align-items-center justify-content-center w-100 p-3'>


<Spinner animation='border' variant='info'/>
</div>
}
                                    </table>
                                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">

                                    {/* pagingation */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>

                </div>
            </div>
        </>
    );
};

export default InventoryManagement;