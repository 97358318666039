/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";
import dashbordIcon from "../../../images/dashboard 1.svg";
import userIcon from "../../../images/users.svg";
import articalIcon from "../../../images/banners.svg";
import notifiIcon from "../../../images/bell 1.svg";
import reportIcon from "../../../images/report-copy.svg";
import policyIcon from "../../../images/Policies.svg";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [""],
      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
      ],
      pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];
    let orders = ["orders"];
    let labOrders = ["lab-orders"];
    let inventory = ["inventory"];
    const loginType = localStorage.getItem("anyTimeHospitalType");

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
                {/* <i className="flaticon-381-networking"></i> */}
                <img src={dashbordIcon} />
                <span className="nav-text pl-4">Dashboard</span>
              </Link>
            </li>
            {loginType === "Pharmacy" && (
              <li className={`${orders.includes(path) ? "mm-active" : ""}`}>
                <Link className=" ai-icon" to="/orders">
                  {/* <i className="flaticon-381-networking"></i> */}
                  <img src={dashbordIcon} />
                  <span className="nav-text pl-4">Orders</span>
                </Link>
              </li>
            )}
            {loginType === "Lab" && (
              <li className={`${labOrders.includes(path) ? "mm-active" : ""}`}>
                <Link className=" ai-icon" to="/lab-orders">
                  {/* <i className="flaticon-381-networking"></i> */}
                  <img src={dashbordIcon} />
                  <span className="nav-text pl-4">Orders</span>
                </Link>
              </li>
            )}

            {loginType === "Pharmacy" && (
              <li className={`${inventory.includes(path) ? "mm-active" : ""}`}>
                <Link className=" ai-icon" to="/inventory">
                  {/* <i className="flaticon-381-networking"></i> */}
                  <img src={dashbordIcon} />
                  <span className="nav-text pl-4">Inventory</span>
                </Link>
              </li>
            )}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
