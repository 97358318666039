
import React, { useReducer, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import moment from "moment/moment";

function LabOrderCard({
  status,
  doctorName,
  medicines,
  patientName,
  orderId,
  orderMode
}) {
    console.log(medicines, "yuyuyuy")

  const navigate = useHistory();

  function goToDetails() {
    navigate.push(`/lab-orders/${orderId}`);
    // setShowInvoiceModal(true)
  }

  function getMedicineName(medicines){
        if(medicines?.length === 1){
            return medicines[0].name
        }else{
            return medicines[0].name + `+${medicines.length - 1} More`
        }
  }

  return (
    <>
      <Card
        className="appointment-card row cursor-pointer animate__animated animate__fadeIn"
        onClick={goToDetails}
      >
        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            Patient
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">{patientName}</span>
          </div>
        </div>

        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            Doctor
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">
              {doctorName}
            </span>
          </div>
        </div>

        <div className="col-2">
          <label htmlFor="" className="appointment-card_label">
            Test
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">
            {getMedicineName(medicines)}
            </span>
          </div>
        </div>
        <div className="col-2">
          <label htmlFor="" className="appointment-card_label">
            Mode
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field border p-1 px-2 rounded">
             {/* {orderMode} */}
             Pickup
            </span>
          </div>
        </div>
      </Card>
    </>
  );
}

export default LabOrderCard;
