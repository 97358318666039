export const GET_USERS = 'admin/auth/user/list';

export const DELETE_USERS = 'admin/auth/userDelete';

export const BLOCK_USERS = 'admin/auth/userBlock';

export const APPROVE_USER_DETAILS = 'admin/auth/userApproved';

export const POST_USER_DETAILS = 'admin/auth/signupUser';

export const POST_MEDICINE = "serviceProvider/pharmacy/medicineInventory"

export const GET_MEDICINE_LIST= "serviceProvider/pharmacy/medicinesList"

export const CHANGE_STATUS = "serviceProvider/pharmacy/changeOrderStatus"

export const CHANGE_LAB_STATUS = "lab/changeOrderStatus"