import { CREATE_INVOICE, CREATE_LAB_INVOICE, GET_LAB_ORDER_LISTS, GET_ORDER_DETAILS, GET_ORDER_LAB_DETAILS, GET_ORDER_LISTS } from "./AuthApiEndPoints"
import axiosInstance from "./Instance"
import { CHANGE_LAB_STATUS, CHANGE_STATUS, GET_MEDICINE_LIST, POST_MEDICINE } from "./User/UserApiEndPoints";



export const getOrders = async (type, page)=>{
        console.log(type, "hjhjhhj")
        return axiosInstance.get(GET_ORDER_LISTS + `?page=${page}&limit=10&type=${type}`);
} 


export const getLabOrders = async (type, page)=>{
        return axiosInstance.get(GET_LAB_ORDER_LISTS + `?page=${page}&limit=10&type=${type}`);
} 

export const getOrderDetails = async (id)=>{
        return axiosInstance.get(GET_ORDER_DETAILS + `?orderId=${id}`);
} 
export const postInvoiceToPatient = async(payload)=>{
        return axiosInstance.put(CREATE_INVOICE, payload);
}


export const postMedicine = async(payload) =>{
        return axiosInstance.post(POST_MEDICINE, payload)
}

export const getInventory = async(search)=>{
        return axiosInstance.get(GET_MEDICINE_LIST + `?page=0&limit=10&search=${search}`);
}

export const changeOrderStatus = async(payload)=>{
        return axiosInstance.put(CHANGE_STATUS , payload)
}


export const getOrderLabDetails = async (id)=>{
        return axiosInstance.get(GET_ORDER_LAB_DETAILS + `?orderId=${id}`);
} 


export const postLabInvoiceToPatient = async(payload)=>{
        return axiosInstance.put(CREATE_LAB_INVOICE, payload);
}


export const changeLabOrderStatus = async(payload)=>{
        return axiosInstance.put(CHANGE_LAB_STATUS , payload)
}
